<template>
  <Dialog :title="title" :visible.sync="isVisible" @closed="closedHandle()">
    <div class="modal-container">
      <div v-if="item">
        <p class="label">{{ item.name }}</p>
        <span class="text-small">{{ item.id }}</span>
      </div>
      <a v-if="canNavigate" class="btn btn-top btn-green" :href="routeHref" target="_blank">Перейти</a>
    </div>
    <ul v-if="item && item.business_regions" class="rhs-tags">
      <li v-for="(tag, index) in item.business_regions" :key="index" class="rhs-tag">
        <span class="rhs-tag-label">{{ tag.name }}</span>
      </li>
    </ul>
    <div v-if="!canNavigate" class="form-item" style="margin-top: 16px;">
      <label class="form-label">Бизнес-регион</label>
      <div class="form-select-wrapper">
        <template>
          <el-select class="form-select" v-model="selectRegion" placeholder="Выберите регион" filtered>
            <el-option v-for="region in regions" :key="region.id" :label="region.name" :value="region.id">
            </el-option>
          </el-select>
        </template>
      </div>
      <button class="btn btn-top btn-green" style="margin-left: 0px; margin-top:10px; width: 100%;"
        :disabled="!selectRegion || selectRegion === ''" @click="addRegion()">Добавить бизнес-регион</button>
    </div>
  </Dialog>
</template>

<script>
import { Dialog } from 'element-ui';

export default {
  name: 'DuplicateModal',
  components: {
    Dialog
  },
  props: {
    title: {
      type: String,
      default: 'Дубликат'
    },
    visible: {
      type: Boolean,
      default: false
    },
    routeName: {
      type: String
    },
    item: {
      type: Object,
      default: null
    },
    canNavigate: {
      type: Boolean,
      default: false
    },
    regions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isVisible: false,
      isLink: false,
      selectRegion: null
    }
  },
  watch: {
    visible(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.isVisible = newValue;
      }
    },
    canNavigate(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.isLink = newValue;
      }
    }
  },
  computed: {
    routeHref() {
      return this.$router.resolve({ name: this.routeName, params: { id: this.item.id } }).href;
    }
  },
  methods: {
    closedHandle() {
      this.isVisible = false;
      this.$emit('close');
    },
    addRegion() {
      this.$emit('add-region', this.selectRegion, this.item.id);
    }
  }
}
</script>

<style scoped>
.modal-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .modal-container {
    flex-direction: column;
    text-align: center;
  }
}

.label {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}

.text-small {
  font-size: 16px;
  line-height: 18px;
  color: #4E4E4E;
}

.btn {
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.rhs-tags {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.rhs-tag {
  background-color: #4FC7B5;
  color: #F8F8F8;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  transition-property: background-color;
  transition-duration: .15s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  padding: 5px 20px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rhs-tag-label {
  transition-property: color;
  transition-duration: .15s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
}
</style>
